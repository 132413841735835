import type { HubTouchpointConfig } from '~/types/configuration'

export const useTouchpointStore = defineStore('touchpoint', () => {
  const touchpointList: Ref<Array<HubTouchpointConfig>> = ref([])
  const computedTouchpointList: ComputedRef<Array<HubTouchpointConfig>> = computed(() => touchpointList.value)

  async function fetchTouchpoints() {
    if (touchpointList.value && touchpointList.value.length) {
      return touchpointList.value
    }

    const { error } = await useHubFetch<Array<HubTouchpointConfig>>('api/v4/lookups/dashboards/touchpoints', {
      onResponse: response => {
        touchpointList.value = response.response._data
      }
    })

    if (error.value) {
      throw new Error('Touchpoints Not Loaded')
    }

    return touchpointList.value
  }

  return {
    // internal refs
    touchpointList,

    // public
    computedTouchpointList,

    fetchTouchpoints
  }
})
